// Components
import React, { useEffect, useState, useRef, forwardRef } from "react";
import { NavLink, useHistory } from "react-router-dom";
// Stylesheets
import "./Navbar.css";
// Images (logos)
import unlvLogo from "../assets/logos/UNLV.png";
import csnLogo from "../assets/logos/CSN.png";
import lsampLogo from "../assets/logos/lsamp-logo.png";
import cccLogo from "../assets/logos/CCCprimary-horizontal-white.png";
import nauLogo from "../assets/logos/NAU.png";
// Icons
import { FiMenu, FiX } from "react-icons/fi";
// Hooks
import useResize from "../hooks/useResize";
import useIsMobile from "../hooks/useIsMobile";

/**
 * The component at the top of every page that provides links to all the different pages and
 * the logos of the universities that host the LSAMP program.
 */
const Navbar = forwardRef((props, ref) => {
  const [scrollY, setScrollY] = useState(window.scrollY); // Keeps track of how far up or down the user has scrolled.
  const [navIsOpen, setNavIsOpen] = useState(false); // On mobile, determines whether the navigation drawer is open or not.
  const [logosHeight, setLogosHeight] = useState(); // Offset value used to move the logo images out of site on scroll.
  const dimensions = useResize(); // Keeps track of the screen's height and width, even as the user resizes it.
  const isMobile = useIsMobile(); // Indicates whether the user is using a mobile device.
  const history = useHistory(); // Used for listening to route changes.
  const logosContainer = useRef(); // Used to get the height of the logo div.

  /**
   * When the component first mounts and whenever the user changes the size of their window, update the logosHeight
   * value accordingly so all the pages are rendered perfectly after the navbar; not too far away and not directly underneath it.
   * Also make sure to close the navbar if the user scales their window from a mobile proportion to a desktop one instantly.
   */
  useEffect(() => {
    setLogosHeight(logosContainer.current.clientHeight + 15);
    if (!isMobile) {
      setNavIsOpen(false);
    }
  }, [isMobile, dimensions]);

  /**
   * On mobile, whenever the user navigates to a different page, close the navigation drawer.
   */
  useEffect(() => {
    return history.listen((location) => {
      setNavIsOpen(false);
    });
  }, [history]);

  /**
   * Whenever the user scrolls, update the scrollY state to reflect their new position.
   * This will be used to determine when to collapse the navbar to a smaller view.
   */
  useEffect(() => {
    window.addEventListener("scroll", (e) =>
      setScrollY(e.currentTarget.scrollY)
    );

    // Event listener cleanup. Return this so the window isn't constantly listening for scrolls.
    return () => {
      window.removeEventListener("scroll", (e) =>
        setScrollY(e.currentTarget.scrollY)
      );
    };
  }, [scrollY]);

  return (
    <nav
      className='navbar-sticky'
      style={{
        // Move the logos out of view after the user has scrolled down by 300px (desktop only feature).
        // Don't do anything if the user is using a mobile device.
        transform: `translateY(${
          scrollY >= 300 && !isMobile ? `${-logosHeight}px` : 0
        })`,
      }}
    >
      {/* Makes the background slightly dark when the navbar is opened (mobile only) */}
      <div
        onClick={() => setNavIsOpen(false)}
        style={{ pointerEvents: isMobile && navIsOpen ? "auto" : "none" }} // Dictates when the user should be able to click it to dismiss the nav drawer.
        className={`dim-background ${!navIsOpen ? "light-background" : ""}`}
      />
      {/**
       * Button used for skipping to the main content of any given page (accessibility feature).
       * For every page in this website, you must give an element id='main-content' so this button
       * knows to jump to it. If this id is not supplied to an element, the button will not do anything,
       * which would be horrible for accessibility.
       */}
      <a className='skip-link' href='#main-content'>
        <div>Skip to Content</div>
      </a>
      <div ref={ref} className='navbar'>
        {/*-------------------- Logo Container --------------------*/}
        <div ref={logosContainer} className='logo-container'>
          <img
            id='unlv-logo'
            className='logo'
            src={unlvLogo}
            alt='Logo for the University of Nevada, Las Vegas.'
          />
          <img
            id='csn-logo'
            className='logo'
            src={csnLogo}
            alt='Logo for the College of Southern Nevada.'
          />
          {/* Clicking on the LSAMP logo will take the user back to the home page. */}
          <NavLink
            className='pageLink'
            activeClassName='active-link'
            to='/'
            exact
          >
            <img
              id='lsamp-logo'
              className='logo'
              src={lsampLogo}
              alt='Logo for the Louis Stokes Alliance for Minority Participation'
            />
          </NavLink>
          <img
            id='coco-logo'
            className='logo'
            src={cccLogo}
            alt='Logo for Coconino Community College.'
          />
          <img
            id='nau-logo'
            className='logo'
            src={nauLogo}
            alt='Logo for Northern Arizona University.'
          />
        </div>

        {/*------------------ Only visible on mobile platforms ------------------*/}
        <NavLink
          // Hide this LSAMP logo when the user is on desktop.
          style={{ display: isMobile ? "flex" : "none", width: "40px" }}
          to='/'
          exact
        >
          <img
            id='lsamp-logo-mobile'
            src={lsampLogo}
            alt='Logo for the Louis Stokes Alliance for Minority Participation'
          />
        </NavLink>
        {/* Hamburger Menu Icon (only visible whne the nav drawer is closed.) */}
        <FiMenu
          id='menu-icon'
          className={navIsOpen ? "icon-hidden" : ""}
          size={35}
          color='#e4be37'
          onClick={() => setNavIsOpen(true)} // Open the drawer when tapped on.
        />
        {/* X Icon (only visible when the nav drawer is opened) */}
        <FiX
          id='menu-icon'
          className={!navIsOpen ? "icon-hidden" : ""}
          size={35}
          color='#e4be37'
          onClick={() => setNavIsOpen(false)} // Close the drawer when tapped on.
        />
        {/*---------------------------------------------------------------------*/}

        {/*-------------------- Pages List --------------------*/}
        <div
          style={
            /**
             * If this is a mobile device and navIsOpen is set to true, extend the width so that the drawer actually opens.
             * Otherwise, completely ignore this styling.
             */
            isMobile && navIsOpen ? { width: "60vw" } : {}
          }
          className='link-container'
        >
          <ul className='links'>
            <li>
              <NavLink
                className='pageLink'
                activeClassName='active-link'
                to='/'
                exact
              >
                Home
              </NavLink>
            </li>
            <li>
              <NavLink
                className='pageLink'
                activeClassName='active-link'
                to='/leadership'
              >
                Leadership
              </NavLink>
            </li>
            <li>
              <NavLink
                className='pageLink'
                activeClassName='active-link'
                to='/activities-and-services'
              >
                Activities and Services
              </NavLink>
            </li>
            <li>
              <NavLink
                className='pageLink'
                activeClassName='active-link'
                to='/newsletter'
              >
                Newsletter
              </NavLink>
            </li>
            <li>
              <NavLink
                className='pageLink'
                activeClassName='active-link'
                to='/research-spotlight'
              >
                Research Spotlight
              </NavLink>
            </li>
            <li>
              <NavLink
                className='pageLink'
                activeClassName='active-link'
                to='/apply'
              >
                Apply
              </NavLink>
            </li>
            <li>
              <NavLink
                className='pageLink'
                activeClassName='active-link'
                to='/connect'
              >
                Connect
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
});

export default Navbar;
