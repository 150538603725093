// Modules
import HeaderCarousel from "../components/HeaderCarousel";
import Footer from "../components/Footer";
import Button from "../components/Button";
import Accordion from "../components/Accordion";
import { Link } from "react-router-dom";
// Styles
import "./Home.css";
// Pictures
import lsampLogo from "../assets/logos/lsamp-logo.png";
import louisStokes from "../assets/decorative-pictures/Louis-Stokes.jpg";
// Background Images
import background1 from "../assets/student-pictures/One STEM Community (3).jpg";
import background2 from "../assets/student-pictures/homeImage.JPG";
import background3 from "../assets/student-pictures/D72339_069.jpg";
import background4 from "../assets/student-pictures/D72339_028.jpg";
import background5 from "../assets/student-pictures/D72339_051.jpg";
import background6 from "../assets/student-pictures/DSC00572.JPG";

/**
 * The main landing page for the site that provides information on SNNA-LSAMP, its origin, a video, and an FAQ section.
 */
export default function Home() {
  /**
   * A JavaScript method for opening a link in a new tag.
   * Use this when using the <a> tag is not preferable.
   * @param {string} url The link to the site that will be opened in a new tab.
   */
  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  /**
   * When adding images to use for the header, make sure they are first imported at the top of this
   * file under the "Background Images" comment. Whatever you name the image(s) doesn't matter,
   * but to actually add them to the header carousel they need to be inserted into the array below.
   */
  const headerImages = [
    background1,
    background2,
    background3,
    background4,
    background5,
    background6,
  ];

  /**
   * Data that will be supplied to the FAQ section.
   * Each element in the array represents a single dropdown accordion that can be clicked on.
   * Every object element must follow this format:
   *      {
   *        title: <string> The clickable header that reveals the text written under it.
   *        message: <string/React Component> The content that only becomes visible once the user clicks on the header.
   *      }
   */
  const FAQContent = [
    {
      title: "What is the goal and what are the objectives of the SNNA-LSAMP?",
      message: (
        <div>
          The goal of the SNNA-LSAMP is to substantially increase the number of STEM 
          baccalaureate degrees earned by students from historically underrepresented minority
          (URM) groups (specifically, African Americans, Hispanic Americans, American Indians,
          Alaska Natives, Native Hawaiians and Native Pacific Islanders) at SNNA-LSAMP institutions.
          SNNA-LSAMP’s objectives include increasing the:
          <ul>
            <li>Number of URMs declaring STEM majors at the Alliance universities;</li>
            <li>
            Persistence rates of URMs in STEM degree programs at Alliance institutions;</li>
            <li>
            Transfer rates of URMs in STEM disciplines from the Alliance’s community colleges 
            to its universities;
            </li>
            <li>
            STEM baccalaureate degree completion rates of URMs at Alliance universities; and</li>
            <li>
            Graduate-school enrollment rates among the Alliance institutions’ URM STEM baccalaureate recipients.</li>
          </ul>
          For purposes of the LSAMP program, the definition of STEM includes most science-,
          technology-, engineering-, and mathematics-associated disciplines but does not include 
          health, clinical, or social sciences. 
        </div>
      ),
    },
    {
      title: "Who are the Alliance partners from the private-industry, government-agency, and non-profit sectors?",
      message: (
        <div>
          The Alliance’s non-institutional partners are:
          <ul>
            <li>Bureau of Land Management Southern Nevada District</li>
            <li>
            Clark County Water Reclamation District</li>
            <li>
            The Nature Conservancy Nevada</li>
            <li>
            Nevada Gold Mines</li>
            <li>
            Nevada National Security Site</li>
            <li>
            Southwest Gas Corporation</li>
            <li>
            Arizona Public Service</li>
            <li>
            Colorado Plateau Center for Health Professions</li>
            <li>
            Loven Contracting</li>
            <li>
            Salt River Project Agricultural Improvement and Power District</li>
            <li>
            Translational Genomics Research Institute (North)</li>
            <li>
            U.S. Forest Service: Coconino National Forest</li>
            <li>
            U.S. Geological Survey Astrogeology Science Center</li>
          </ul>
        </div>
      ),
    },
    {
      title: "What are the Alliance’s activities and services?",
      message: (
        <div>
          The Alliance’s activities and services are:
          <ul>
            <li>STEM Transfer Transition Success Program (TTSP)</li>
            <li>
            University-to-Community-College Near-Peer Mentoring</li>
            <li>
            STEM Post-Baccalaureate Transition Success Program (PBTSP)</li>
            <li>
            STEM Undergraduate Research (UGR)</li>
            <li>
            Cross-Institutional, Discord-Based Tutoring for Select STEM Gateway Courses</li>
            <li>
            Outreach to Draw Underrepresented Minority University Undergraduates into STEM Disciplines</li>
            <li>
            Annual Alliance Conference</li>
          </ul>
          More information about each activity and service can be found under {" "}
          <Link tabIndex={-1} to='/activities-and-services'>
            Activities and Services
          </Link>{" "}.
        </div>
      ),
    },
    {
      title: "What are the eligibility requirements for each of SNNA-LSAMP’s activities and services?",
      message: (
        <p style={{ margin: 0 }}>
          The eligibility requirements for each activity and service can be found under {" "}
          <Link tabIndex={-1} to='/activities-and-services'>
            Activities and Services
          </Link>{" "}.
        </p>
      ),
    },
    {
      title: "How do I apply to become an SNNA-LSAMP participant?",
      message: (
        <p style={{ margin: 0 }}>
          Click on the{" "}
          <Link tabIndex={-1} to='/apply'>
            Apply
          </Link>{" "}
          link at the top of this page for instructions about how to apply for
          admission to the SNNA-LSAMP program on your campus.
        </p>
      ),
    },
    {
      title: "Is there a cost to students at Alliance institutions to participate in SNNA-LSAMP activities and services?",
      message:
        "No. All SNNA-LSAMP activities and services are free to eligible students. However, eligibility requirements vary between services, and resource constraints limit the number of students who can participate in each of the activities and services offered on a given Alliance campus.",
    },
    {
      title:
        "How much time do I have to commit to SNNA-LSAMP if I become a participant?",
      message:
        "The time commitment varies depending on the specific SNNA-LSAMP services you decide to utilize.",
    },
    {
      title: "How do I know if I qualify to be in SNNA-LSAMP?",
      message: (
        <div>
          You qualify if you:
          <ul>
            <li>Are an undergraduate at UNLV, NAU, CSN, or CCC;</li>
            <li>
              Are a member of one or more historically underrepresented minority
              groups (specifically, African Americans, Hispanic Americans,
              American Indians, Alaska Natives, Native Hawaiians and Native
              Pacific Islanders); and
            </li>
            <li>
              Meet the specific eligibility requirements established by the
              SNNA-LSAMP office at your institution. Check your institution’s
              SNNA-LSAMP website for more information about these requirements.
            </li>
          </ul>
        </div>
      ),
    },
    {
      title: "How will I benefit from participating in the program?",
      message:
        "SNNA-LSAMP can help you boost your academic performance; network with STEM students, faculty members, and employers; transfer to UNLV or NAU (if you are currently at CSN or CCC); graduate with a baccalaureate degree in a STEM field; and prepare for entry into graduate-level studies in STEM.",
    },
    {
      title: "What types of services are available to SNNA-LSAMP participants?",
      message: (
        <div>
          SNNA-LSAMP offers services that aim to help students be successful in
          STEM. Some of these services include: mentoring, one-on-one academic
          support, financial aid counseling, and{" "}
          <Link tabIndex={-1} to='/activities-and-services'>
            more!
          </Link>
        </div>
      ),
    },
  ];

  return (
    <main className='container'>
      <HeaderCarousel
        headerStyle={{ backgroundPosition: "center 40%" }}
        backgroundImages={headerImages}
        title={
          "Southern Nevada Northern Arizona (SNNA) Louis Stokes Alliance for Minority Participation (LSAMP)"
        }
        subheader={
          "Promoting postsecondary success in STEM for the region's underrepresented minority students"
        }
      />

      {/*================================ "What is LSAMP?" Section ================================*/}
      <div id='main-content' className='content-container'>
        <h2 className='content-header'>What is SNNA-LSAMP?</h2>
        <div className='content'>
          <p>
            The Southern Nevada Northern Arizona Louis Stokes Alliance for
            Minority Participation (hereafter referred to as SNNA-LSAMP or “the
            Alliance”) was founded in 2017 and funded through a National Science
            Foundation (NSF) grant (EES 2307200). The Alliance joins four higher
            education institutions--the University of Nevada, Las Vegas (UNLV),
            Northern Arizona University (NAU), the College of Southern Nevada
            (CSN), and Coconino Community College (CCC)-- and 13 private-
            industry/government-agency/non-profit partners in pursuit of the goal
            of substantially increasing the number of STEM baccalaureate degrees
            earned by students from historically underrepresented minority
            groups (specifically, African Americans, Hispanic Americans,
            American Indians, Alaska Natives, Native Hawaiians and Native
            Pacific Islanders). In order to do this, SNNA-LSAMP provides free
            services to students and supports them through a comprehensive
            approach to student development, community building, and career
            readiness.
            <br />
          </p>
          <img
            id='lsamp-picture'
            src={lsampLogo}
            alt='Logo for the Louis Stokes Alliance for Minority Participation'
          />
        </div>

        {/*================================ "History" Section ================================*/}
        <h2 className='content-header'>History of LSAMP</h2>
        <div id='louis-section' className='content'>
          <img
            id='louisStokes-picture'
            src={louisStokes}
            alt='Congressman Louis Stokes'
          />
          <p>
            The LSAMP Program is a nationwide NSF initiative in honor of Louis
            Stokes (1925-2015), who served in Congress for 30 years and was the
            first African American from the state of Ohio elected to the U.S.
            House of Representatives. Stokes was the son of a widow who cleaned
            homes for a living, and he grew up in public housing along with his
            brother. He served in a segregated army unit during World War II and
            went on to attend college and law school. He later became a
            prominent attorney prior to running for office. Stokes also became
            the first African American to earn a seat on the powerful House
            Appropriations Committee, where he addressed many of the inequities
            faced by disadvantaged populations. He supported and was a sponsor
            of many bills and resolutions intended to help those populations,
            including H.R. 5240—101st Congress: Summer Science Academy of 1990
            and H.R. 775—102nd Congress: Summer Science Academy of 1991.
          </p>
        </div>
      </div>
      <hr />
      {/*================================ Embedded Video ================================*/}

      {/*================================ FAQ Section ================================*/}
      <div className='content-container'>
        <h2 className='content-header'>FAQ</h2>
        <div className='video'>
          <div className='iframe-container'>
            <iframe
              className='responsive-iframe'
              src='https://www.youtube.com/embed/tvmaoGs8jiQ'
              title='SNNA-LSAMP Informational Video'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
              frameBorder={0}
              allowFullScreen
            ></iframe>
          </div>
        </div>
        <Accordion content={FAQContent} />
      </div>

      {/*================================ Button that links to the official LSAMP site ================================*/}
      <div className='content-container'>
        <Button
          text='Learn more about the LSAMP program by visiting the LSAMP National Science Foundation page!'
          variant='yellow'
          onClick={() =>
            openInNewTab(
              "https://beta.nsf.gov/funding/opportunities/louis-stokes-alliances-minority-participation"
            )
          }
        />
      </div>

      <Footer />
    </main>
  );
}
