import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styles from "./Header.module.css";

/**
 * Displays a picture container with some text for the header and a blue bar of additional text for the subheader.
 * @param {Object} headerStyle      Style object used to inject additional styles to the header component.
 * @param {Object} subheaderStyle   Style object used to inject additional styles to the subheader component.
 * @param {string} backgroundImage  URL to the image that will be used as the background for the header.
 * @param {string} title            Actual text for the header content.
 * @param {string, element} subheader        Actual text for the subheader content.
 */
export default function Header({
  headerStyle,
  subheaderStyle,
  backgroundImage,
  title,
  subheader,
}) {
  const [pageHasMounted, setPageHasMounted] = useState(false); // Used for fading in and moving down animations.

  /**
   * When the component mounts, set the associated state to true so some animations can trigger.
   */
  useEffect(() => {
    setPageHasMounted(true);
  }, []);

  return (
    <>
      {/*----- Header -----*/}
      <div
        id={styles.headerContainer}
        style={{
          ...headerStyle,
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)),
          url(${backgroundImage})`,
        }}
      >
        <h1
          className={pageHasMounted ? styles.fadeInMoveDown : styles.invisible}
          id={styles.header}
        >
          {title}
        </h1>
      </div>

      {/*----- Subheader -----*/}
      <div style={subheaderStyle} id={styles.subheaderContainer}>
        <h2
          className={pageHasMounted ? styles.fadeIn : styles.invisible}
          id={styles.subheader}
        >
          {subheader}
        </h2>
      </div>
    </>
  );
}

Header.propTypes = {
  /**Style object used to inject additional styles to the header component.*/
  headerStyle: PropTypes.object,
  /**Style object used to inject additional styles to the subheader component.*/
  subheaderStyle: PropTypes.object,
  /**URL to the image that will be used as the background for the header.*/
  backgroundImage: PropTypes.string.isRequired,
  /**Actual text for the header content.*/
  title: PropTypes.string.isRequired,
  /**Actual text for the subheader content.*/
  subheader: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
};
