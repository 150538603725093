// Modules
import Header from "../components/Header";
import Footer from "../components/Footer";
// Stylesheet
import styles from "./Leadership.module.css";
// Images
import background from "../assets/student-pictures/Leadershippic.jpg";

/**
 * Quick page that provides bulleted lists about some of the faculty in charge of running SNNA-LSAMP.
 */
export default function Leadership() {
  return (
    <main className='container'>
      <Header
        headerStyle={{ backgroundPosition: "center 8%" }}
        backgroundImage={background}
        title='Leadership'
        subheader='Discover all the wonderful people who help make SNNA-LSAMP possible'
      />
      <div id='main-content' className={styles["page-container"]}>
        <h2 className={styles["page-header"]}>Governing Board</h2>

        <ul className={styles["bullets"]}>
          <li>
            Chris L. Heavey, Ph.D., Executive Vice President and Provost, UNLV
            (Chair)
          </li>
          <li>
            Karen Pugliesi, Ph.D., Interim Provost and Vice President for
            Academic Affairs, NAU
          </li>
          <li>
            Douglas B. Sims, Ph.D., Dean, School of Science & Mathematics, CSN
          </li>
          <li>
            Tony Williams, Vice President of Student Services, CCC</li>
        </ul>
      </div>

      <div className={styles["page-container"]}>
        <h2 className={styles["page-header"]}>P.I.s and co-P.I.s</h2>
        <ul className={styles["bullets"]}>
          <li>
            Chris L. Heavey, Ph.D., Executive Vice President and Provost, UNLV
            (Prime Award PI)
          </li>
          <li>
            Melissa Morris, Ph.D., Associate Professor in Residence, Howard R.
            Hughes College of Engineering, UNLV (Prime Award co-PI)
          </li>
          <li>
            Morgan Vigil-Hayes, Ph.D., Assistant Professor, Informatics, Computing, 
            and Cyber Systems, NAU (Subaward PI)
          </li>
          <li>
            Peter Lanagan, Ph.D., Professor, Department of Physical Sciences,
            CSN (Subaward PI)
          </li>
          <li>
            David Asencio, MS, Dean of Student Development, CCC (Subaward
            PI)
          </li>
        </ul>
      </div>
      <Footer />
    </main>
  );
}
