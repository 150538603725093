// Modules
import { useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Accordion from "../components/Accordion";
// Stylesheets
import "./ResearchSpotlight.css";
// Images
import background from "../assets/student-pictures/D72339_061.jpg";

/**
 * Displays all the research projects created by SNNA-LSAMP participants according to their subject matter.
 *
 * NOTE: All the components necessary to create a Research Poster Tile are located in TSV files in "../assets/research-posters"
 * according to their subject.
 * @see https://docs.google.com/spreadsheets/d/1yY_BNFmcWQQHUelCRTpImy3BOTZB376P3e0MqZOuYtQ/edit?usp=sharing To view the spreadsheet where these
 * TSV files originally came from.
 * @see https://drive.google.com/drive/folders/1spG5WroAHRlgq3-VoGBI-QTFUBh-MSts?usp=sharing To view all the image and PDF files (if you need edit access,
 * please request it).
 * @see https://docs.google.com/document/d/1-fjjoRR7YZB5UpIJz0Lkw6t_rIchcF51CNr2xZSELE8/edit?usp=sharing To view the documentation to get a more detailed
 * explanation of how this page works, as it is the most complicated and prone to breaking one in the whole website.
 */
export default function ResearchSpotlight() {
  const [posterData, setPosterData] = useState({}); // Contains all the data to create each individual ResearchTile component.
  const [accordionContent, setAccordionContent] = useState([]); // Contains the data necessary to create the entire Accordion component.

  /**
   * When the component mounts, take in all the data from the TSV files and format them so they can be read properly
   * by the ResearchTile component.
   */
  useEffect(() => {
    /**
     * Imports all the files in a given folder without having to manually import them each at
     * the top of the page.
     * @param {NodeRequire} r A require.context() function that indicates the path of all the dynamically imported files.
     * @returns An object containing the name of each file as the key and the actual import path as the value.
     */
    function dynamicallyImport(r) {
      let imports = {};
      r.keys().map(
        (item) =>
          (imports[
            item
              .replace(/^\.\//, "") // Removes the "./" from the beginning of the name of the file.
              .replace(/\.tsv$/, "") // Removes the ".tsv" from the end of the name of the file.
          ] = r(item).default)
      );
      return imports;
    }

    /**
     * Takes a given TSV file containing the research posters' titles, image links, and pdf links, and formats
     * it such that the ResearchTile component can read it.
     * @param {string} file The path to a TSV file that contains three columns.
     * @returns An array of objects for each row, with the keys of each object being title, thumbnail, and pdfLink.
     * These keys can be used by the ResearchTile component's props.
     */
    async function extractData(file) {
      const response = await fetch(file); // Must fetch files rather than import.
      const text = (await response.text()).split("\n"); // Makes the text readable and removes all newline characters.

      // For each row, create an object containing the title, thumbnail, and pdfLink data.
      return text.map((element) => {
        const poster = element.replace("\r", "").split("\t"); // Remove all return characters, then create a new element for each column (separated by a tab).
        return {
          title: poster[0],
          thumbnail: poster[1],
          pdfLink: poster[2],
        };
      });
    }

    // Import all the TSV files so the data can be extracted from them.
    const subjects = dynamicallyImport(
      require.context(`../assets/research-posters/`, false)
    );

    /**
     * The following code will create an object that is structured as such:
     *
     *  {
     *    subjectName: [
     *      [
     *        title: {string},
     *        thumbnail: {string},
     *        pdfLink: {string},
     *      ]
     *      ...
     *    ]
     *    ...
     *  }
     *
     * This will be used to create all the Accordion sections and the ResearchTiles that are within them.
     */
    let compiledData = {};
    for (let name in subjects) {
      compiledData[name] = extractData(subjects[name]);
    }

    // Put the "Other" subject section at the end of the list, that way it is always the last header in the accordion.
    let other = { Other: compiledData.Other };
    delete compiledData.Other;
    compiledData = { ...compiledData, ...other };

    setPosterData(compiledData);
  }, []);

  /**
   * Whenever the posterData state changes, create an array of objects that can be read by the Accordion component.
   */
  useEffect(() => {
    async function formatAccordionContent() {
      // Only proceed with this function once the posterData state has been initialized, otherwise the for loop won't run.
      if (!!Object.keys(posterData).length) {
        let content = []; // Stores the posterData in a format that corresponds to the content prop on the Accordion component.
        for (let subject in posterData) {
          const subjectArr = await posterData[subject]; // Must await this to get actual array data.
          content.push({
            title: subject,
            message: (
              <div className='research-container'>
                {
                  // Must await this as well; returns a promise due to the fetch command that was used earlier.
                  await subjectArr.map((data, index) => (
                    <ResearchTile
                      key={index}
                      title={data.title}
                      thumbnail={data.thumbnail}
                      pdfLink={data.pdfLink}
                    />
                  ))
                }
              </div>
            ),
          });
        }
        setAccordionContent(content);
      }
    }

    formatAccordionContent();
  }, [posterData]);

  /**
   * For each section, create an array with the file locations, thumbnails, etc as objects.
   * This info will be mapped into a series of tiles for that specific section in the Accordion.
   */
  return (
    <main className='container'>
      <Header
        title='Research Spotlight'
        subheader='Take a look at the over 100 unique research projects conducted by SNNA-LSAMP participants!'
        backgroundImage={background}
        headerStyle={{ backgroundPosition: "center 20%" }}
      />
      <div className='research-accordion'>
        {/* Only display the Accordion if its content has been initialized; takes a second due to the asynchronous method of reading the TSV files. */}
        {accordionContent.length > 0 ? (
          <Accordion content={accordionContent} />
        ) : (
          <></>
        )}
      </div>
      <Footer />
    </main>
  );
}

/**
 * Component used to display every research poster in the Accordion.
 * Each Tile contains a background image of the actual research poster and a title that corresponds to the
 * title of that poster.
 * It will act like a button that takes the user to a new tab containing the research poster's PDF when clicked.
 *
 * The following params should be extracted from the provided TSV files located in "../assets/research-posters".
 * @param {string} title The title of the research poster.
 * @param {string} thumbnail Link to the image of the poster that will be displayed in the background of the tile.
 * @param {string} pdfLink Link to the actual PDF file of the poster that will open up in a new tab when the tile is clicked.
 */
function ResearchTile({ title, thumbnail, pdfLink }) {
  return (
    <a
      className='tile'
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)), url(${thumbnail})`,
      }}
      href={pdfLink}
      target='_blank'
      rel='noopener noreferrer'
    >
      <p>{title}</p>
    </a>
  );
}
