// Modules
import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import PropTypes from "prop-types";
// Stylesheets
import styles from "./HeaderCarousel.module.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

/**
 * Displays a container of revolving images with some text for the header and a blue bar of additional text for the subheader.
 * @param {Object} headerStyle       Style object used to inject additional styles to the header component.
 * @param {Object} subheaderStyle    Style object used to inject additional styles to the subheader component.
 * @param {Array} backgroundImages  URLs to the images that will be used as the background for the header
 * @param {string} title             Actual text for the header content.
 * @param {string} subheader         Actual text for the subheader content.
 */
export default function HeaderCarousel({
  headerStyle,
  subheaderStyle,
  backgroundImages,
  title,
  subheader,
}) {
  const [pageHasMounted, setPageHasMounted] = useState(false); // Used for fading in and moving down animations.

  /**
   * Deals with graphical glitches that are prone to occur on the live site.
   */
  useEffect(() => {
    setPageHasMounted(true);
  }, []);

  return (
    <>
      {/*----- Header -----*/}
      <div className={`${styles.sliderContainer} ${styles.headerContent} `}>
        <Slider
          className={styles.sliderContainer}
          arrows={false}
          dots={false}
          draggable={false}
          swipe={false}
          touchMove={false}
          pauseOnHover={false}
          autoplay
          autoplaySpeed={5000}
          speed={1000}
        >
          {backgroundImages.map((image, index) => {
            return (
              <div
                key={index}
                style={{
                  ...headerStyle,
                }}
              >
                <img
                  className={styles.background}
                  src={image}
                  alt='LSAMP members'
                />
              </div>
            );
          })}
        </Slider>
        <h1
          className={pageHasMounted ? styles.fadeInMoveDown : styles.invisible}
          id={styles.header}
        >
          {title}
        </h1>
      </div>

      {/*----- Subheader -----*/}
      <div style={subheaderStyle} id={styles.subheaderContainer}>
        <h2
          className={pageHasMounted ? styles.fadeIn : styles.invisible}
          id={styles.subheader}
        >
          {subheader}
        </h2>
      </div>
    </>
  );
}

HeaderCarousel.propTypes = {
  /**Style object used to inject additional styles to the header component.*/
  headerStyle: PropTypes.object,
  /**Style object used to inject additional styles to the subheader component.*/
  subheaderStyle: PropTypes.object,
  /**URLs to the images that will be used as the background for the header.*/
  backgroundImages: PropTypes.arrayOf(PropTypes.string).isRequired,
  /**Actual text for the header content.*/
  title: PropTypes.string.isRequired,
  /**Actual text for the subheader content.*/
  subheader: PropTypes.string.isRequired,
};
