import { useState, useEffect } from "react";

/**
 * A hook that keeps track of the user's device width and returns whether it is small enough to be considered a mobile device.
 * @returns Whether the user's device is a mobile device.
 */
export default function useIsMobile() {
  const [width, setWidth] = useState(window.innerWidth); // Keeps track of the width of the screen.

  /**
   * Whenever the screen is resized, update the width state.
   */
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }

    // Listen for when the user resizes their screen.
    window.addEventListener("resize", handleResize);
    // Event listener clean-up.
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  return width <= 768; // A width lower than 768px is considered a mobile device.
}
