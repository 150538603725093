// Modules
import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
// Styles
import styles from "./Accordion.module.css";
// Icons
import { FaChevronDown } from "react-icons/fa";
// Hooks
import useResize from "../hooks/useResize";

/**
 * This is a type of dropdown menu where clicking on a header reveals a chunk of text underneath it in a smooth, animated fashion.
 * This is primarily used in the Home page, but it is flexible enough to be used anywhere throughout the site.
 * @param {Array} content Provides text to the different parts of the accordion. Each element in the array represents a new section of the accordion.
 *                        The array must follow the following shape:
 *                        {
 *                          title: "Example Header"
 *                          message: "Text that appears under the header after it has been clicked."
 *                        }
 */
export default function Accordion({ content }) {
  return (
    <div className={styles.accordionWrapper}>
      {
        // For each element in content, create an accordion item that will combine to create the larger accordion component.
        content.map(({ title, message }, index) => {
          return (
            <AccordionItem
              key={index}
              accordionSize={content.length}
              index={index}
              title={title}
              message={message}
            />
          );
        })
      }
    </div>
  );
}

/**
 * This is an individual accordion section. The overall Accordion component is comprised of many of these items to create a full
 * accordion. This is done to abstract some of the complexity and to make the styling more consistent.
 * This component is not available outside of this file; only to the Accordion component.
 * @param {Number} accordionSize Number of AccordionItems that will be created in the Accordion component.
 * @param {Number} index The order of this item in relation to all the others in the Accordion component.
 * @param {String} title The header text.
 * @param {String} message The text of the content section that gets revealed when the title is clicked.
 */
function AccordionItem({ accordionSize, index, title, message }) {
  const [isActive, setIsActive] = useState(false); // Dictates whether the content section of this item should be opened or closed.
  const [contentHeight, setContentHeight] = useState(); // Height of the item's content (based on the amount of text it contains).
  const dimensions = useResize(); // Keeps track of the screen's height and width, even as the user resizes it.
  const arrowSize = "28px"; // Size of the arrow icon that displayed near the header.
  const contentDiv = useRef(); // Used to get the scrollHeight of the content section's text.

  /**
   * The collapsing feature of this component is based on the maxHeight of the content section. When an item is closed, its maxHeight is
   * set to zero, that way the content section isn't being displayed. When an item is opened, the max height must increase so the content
   * section can be revealed. In order to make this animation from closed to opened as smooth as possible, the maxHeight is set to the
   * scrollHeight of the content section's text, which is a measurement of how long that text is.
   * This value is updated when the component mounts and whenever the content within the contentDiv changes size (i.e. whenever the window is resized).
   */
  useEffect(() => {
    setContentHeight(contentDiv.current.scrollHeight);
  }, [dimensions, contentDiv.current?.scrollHeight]);

  return (
    <button
      // If this accordion is the last one in the list, don't give it a bottom border (prevents outer border from overlapping).
      style={{
        borderBottom: accordionSize === index + 1 ? "none" : "",
      }}
      // If this item has been clicked, change the color of the background.
      className={`${styles.accordionItemContainer} ${
        isActive ? styles.opened : ""
      }`}
    >
      <div
        onClick={() => {
          setIsActive(!isActive); // Open/close the content container.
        }}
        className={styles.accordionHeader}
      >
        <h2 id={styles.headerText}>{title}</h2>
        <FaChevronDown
          size={arrowSize}
          // When this item is clicked, rotate the arrow in the opposite direction.
          className={`${styles.arrow} ${isActive ? styles.arrowOpened : ""}`}
        />
      </div>
      <div
        // If this item has been clicked, open the container section by setting the appropriate className.
        className={`
        ${styles.accordionContent} 
        ${isActive ? styles.contentOpened : styles.contentClosed}
      `}
        style={{
          // Adjust the maxHeight to the content's calculated height so the entire message can be shown.
          maxHeight: isActive ? contentHeight : 0,
        }}
      >
        <div
          style={{ width: "100%" }} // Ensures any content on the inside can utilize the fill width of the space.
          ref={contentDiv}
        >
          {message}
        </div>
      </div>
    </button>
  );
}

Accordion.propTypes = {
  /**
   * Provides text to the different parts of the accordion. Each element in the array represents a new section of the accordion.
   * The array must follow the following shape:
   * ```js
   * {
   *  title: "Example Header"
   *  message: "Text that appears under the header after it has been clicked."
   * }
   * ```
   */
  content: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    })
  ).isRequired,
};
