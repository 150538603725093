import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

/**
 * Keeps track of when the window is resized.
 * @returns The current width and height of the screen.
 */
export default function useResize() {
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const history = useHistory();

  useEffect(() => {
    // Whenever the screen is resized, update the dimensions state.
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }

    // Listen for when the user resizes their screen.
    window.addEventListener("resize", handleResize);

    // Event listener clean-up.
    return () => {
      window.removeEventListener("resize", handleResize);
      history.listen((location) => {
        handleResize();
      });
    };
  }, [dimensions, history]);

  return dimensions;
}
