// Components
import React, { useEffect, useRef, useState } from "react";
import { Switch, Route, useHistory } from "react-router-dom";
import Navbar from "./components/Navbar";
import ScrollToTopBtn from "./components/ScrollToTopBtn";
// Stylesheets
import "./App.css";
import "./index.css";
// Pages
import Home from "./pages/Home";
import Leadership from "./pages/Leadership";
import ProgramBenefits from "./pages/ProgramBenefits";
import ResearchSpotlight from "./pages/ResearchSpotlight";
import Newsletter from "./pages/Newsletter";
import Apply from "./pages/Apply";
import Connect from "./pages/Connect";

export default function App() {
  const [navHeight, setNavHeight] = useState(0); // The height of the navigation bar that will be used to offset the actual content of the pages from it.
  const [insertedComment, setInsertedComment] = useState(false); // Prevents the first HTML comment from being inserted multiple times.
  const nav = useRef(); // Used to get the navigation bar's height.
  const history = useHistory(); // Used for listening to route changes.
  const refocus = useRef(); // Used for refocusing on each page change.

  /**
   * Inserts a comment at the beginning of the HTML document that let's the world know that I'm the father of this baby.
   * Can't manually do this in the HTML document because Babel and Webpack remove all comments when creating the build folder.
   */
  useEffect(() => {
    if (!insertedComment) {
      const comment = document.createComment(
        " Created by Jordan King (https://github.com/KingJordan152) "
      );
      document.body.insertBefore(comment, document.body.firstChild);
      setInsertedComment(true);
    }
  }, [insertedComment]);

  /**
   * Whenever the height of the navbar changes, update the navHeight state so the spacer can be adjusted accordingly.
   * This prevents the content from rendering underneath the navbar.
   */
  useEffect(() => {
    // ResizeObserver listens to the navbar and returns its height whenever it changes.
    const navResize = new ResizeObserver((e) => {
      let height = e[0].contentRect.height; // Since we only have one element, get the height of the first one.
      setNavHeight(height);
    });
    let navRef = nav.current;
    navResize.observe(navRef);

    return () => navResize.unobserve(navRef); // Listener cleanup.
  }, [navHeight]);

  /**
   * If the user changes the route, scroll back to the top.
   * Fixes a bug where changing routes would inherit the same scroll position as the previous
   * route on the new route.
   * This also refocuses the main div so that tabbing for screen readers can start back from the
   * top of the page rather than where the user last left off.
   */
  useEffect(() => {
    return history.listen((location) => {
      refocus.current?.focus();
      window.scrollTo(0, 0);
    });
  }, [history]);

  return (
    <main ref={refocus} tabIndex={-1} className='App'>
      <Navbar ref={nav} />
      {/* Spacer used to offset the Navbar from the rest of the content */}
      <div
        style={{
          height: navHeight,
          backgroundColor: "var(--blue)",
        }}
      />
      <Switch>
        <Route path='/' component={Home} exact />
        <Route path='/leadership' component={Leadership} />
        <Route path='/activities-and-services' component={ProgramBenefits} />
        <Route path='/research-spotlight' component={ResearchSpotlight} />
        <Route path='/newsletter' component={Newsletter} />
        <Route path='/apply' component={Apply} />
        <Route path='/connect' component={Connect} />
        <Route component={Error} />
      </Switch>
      <ScrollToTopBtn />
    </main>
  );
}

/**
 * Component that displays a 404 message when the user attempts to route to a page that doesn't exist.
 */
const Error = () => {
  return (
    <main
      style={{
        display: "flex",
        height: "100%",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "25px",
        backgroundColor: "white",
      }}
    >
      <h1>ERROR: 404</h1>
      <p style={{ fontSize: "20px" }}>The page you requested was not found</p>
    </main>
  );
};
