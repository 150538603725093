import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
// Stylesheets
import "./Connect.css";
import "../assets/fonts/Spinnaker/Spinnaker-Regular.ttf";
// Images
import unlvLogo from "../assets/logos/UNLV.png";
import csnLogo from "../assets/logos/CSN.png";
import cccLogo from "../assets/logos/CCCprimary-horizontal-white.png";
import nauLogo from "../assets/logos/NAU.png";
import background from "../assets/decorative-pictures/hoover-dam-bridge.jpg";

/**
 * Displays information to the user on ways they can connect with their campus' LSAMP division.
 * It also provides various forms of contact for these divisions in the form of boxes.
 */
export default function Connect() {
  return (
    <main className='container'>
      <Header
        backgroundImage={background}
        title={"Connect With Us"}
        subheader={
          "Contact your school’s SNNA-LSAMP office if you want more information or have questions."
        }
      />
      {/*-------------------------------------------Contact Boxes Section-------------------------------------------*/}
      <div id='main-content' className='contact-section'>
        {/*------UNLV Contact Info------*/}
        <div className='box-container grow'>
          <img
            className='box-header-img'
            src={unlvLogo}
            alt='Logo for the University of Nevada, Las Vegas'
          />
          <p className='box-text'>
            <strong>UNLV LSAMP Office</strong>
            <a className='email-link' href='mailto: lsamp@unlv.edu'>
              lsamp@unlv.edu
            </a>
            <br />
          </p>
          <p className='box-text'>
            <strong>Melissa Morris</strong>
            (702) 895-5105
            <a className='email-link' href='mailto: Melissa.Morris@unlv.edu'>
            Melissa.Morris@unlv.edu
            </a>
            <br />
          </p>
        </div>

        {/*------CSN Contact Info------*/}
        <div className='box-container grow'>
          <img
            id='csn'
            className='box-header-img'
            src={csnLogo}
            alt='Logo for the College of Southern Nevada'
          />
          <p className='box-text'>
            <strong>CSN LSAMP Office</strong>
            (702) 651-3635
            <a className='email-link' href='mailto: lsamp@csn.edu'>
              lsamp@csn.edu
            </a>
            <br />
          </p>
        </div>

        {/*------CCC Info------*/}
        <div className='box-container grow'>
          <img
            className='box-header-img'
            src={cccLogo}
            alt='Logo for Coconino Community College'
          />
          <p className='box-text'>
            <strong>Daniela Tirado Barva, <br></br>Site Coordinator</strong>
            (928) 226-4316
            <a className='email-link' href='mailto: lsamp@coconino.edu'>
              lsamp@coconino.edu
            </a>
            <br />
          </p>
        </div>

        {/*------NAU Contact Info------*/}
        <div className='box-container grow'>
          <img
            id='nau'
            className='box-header-img'
            src={nauLogo}
            alt='Logo for Northern Arizona University'
          />
          <p className='box-text'>
            <b>NAU LSAMP Office</b>
            (928) 523-6740
            <a className='email-link' href='mailto: LSAMP@nau.edu'>
              LSAMP@nau.edu
            </a>
            <br />
          </p>
        </div>
      </div>

      <Footer />
    </main>
  );
}
