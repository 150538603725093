// Modules
import React, { useState, useEffect } from "react";
import { BsFillArrowUpCircleFill, BsArrowUpCircle } from "react-icons/bs";
import useIsMobile from "../hooks/useIsMobile";
// Stylesheet
import "./ScrollToTopBtn.css";

/**
 * A button that appears after the user has scrolled down the page a certain amount that allows them to scroll
 * back to the very top.
 */
export default function ScrollToTopBtn() {
  const [scrollY, setScrollY] = useState(window.scrollY); // Keeps track of how far down the user has scrolled.
  const isMobile = useIsMobile();

  /**
   * Whenever the user scrolls, update the scrollY state to reflect their new position.
   * This will be used to determine when to collapse the navbar to a smaller view.
   */
  useEffect(() => {
    window.addEventListener("scroll", (e) =>
      setScrollY(e.currentTarget.scrollY)
    );

    // Event listener cleanup. Return this so the window isn't constantly listening for scrolls.
    return () => {
      window.removeEventListener("scroll", (e) =>
        setScrollY(e.currentTarget.scrollY)
      );
    };
  }, [scrollY]);

  /**
   * When the button is pressed, scroll the page back to the very top.
   * Unfortunately, the smooth scrolling behavior only works on desktop, not mobile.
   */
  function handleScrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  return (
    <div
      className={`scroll-btn-container ${scrollY >= 600 ? "visible" : ""}`}
      onClick={handleScrollToTop}
    >
      <BsArrowUpCircle
        className='scroll-btn'
        size={isMobile ? 65 : 85}
        color='#e4be37'
      />
      <BsFillArrowUpCircleFill
        className='scroll-btn'
        size={isMobile ? 65 : 85}
        color='#255ba8'
      />
    </div>
  );
}
