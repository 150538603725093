// Stylesheet
import "./Footer.css";
// Assets
import nsfLogo from "../assets/logos/NSF_logo.png";

/**
 * The component that rests at the end of every page that displays information about CAEO
 * and a disclaimer about the NFS's involvement with LSAMP.
 */
export default function Footer() {
  return (
    <main className='footer-container'>
      {/*-------------------- CAEO/LSAMP Info Container --------------------*/}
      <div className='footer'>
        <p id='caeo-container'>
          <b>SNNA-LSAMP</b>
          <br />
          University of Nevada, Las Vegas
          <br />
          4505 S. Maryland Parkway,
          <br />
          Las Vegas, NV 89154-2006
          <br />
        </p>

        <hr id='divider' />

        {/*-------------------- NSF Disclaimer Container --------------------*/}
        <p id='nsf-container'>
          <img
            id='nsf-logo'
            src={nsfLogo}
            alt='Nation Science Foundation logo.'
          />
          SNNA-LSAMP is funded through the National Science Foundation (NSF)
          <br />
          under grant EES 2307200. Any opinions, findings, and conclusions or
          <br />
          recommendations expressed in this material are those of the author(s)
          <br />
          and do not necessarily reflect the views of the NSF.
          <br />
        </p>
      </div>
    </main>
  );
}
