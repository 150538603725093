// Modules
import PropTypes from "prop-types";
// CSS
import styles from "./Button.module.css";
// Icons
import { FaExternalLinkAlt } from "react-icons/fa";

/**
 * A simple button that executes a supplied function.
 * Can be used anywhere throughout the website.
 * @param {string} text The text that will be displayed in the button.
 * @param {Function} onClick Function that will execute upon clicking the button.
 * @param {string} variant Changes the background color and overall theme of the button.
 *                         Must be either `"blue"` or `"yellow"`.
 */
export default function Button({ text, onClick, variant = "yellow" }) {
  const blue = "#255ba8"; // Global color for blue.
  const yellow = "#e4be37"; // Global color for yellow.

  return (
    <button
      // Since these styles are dynamic, they are placed here instead of in the CSS file.
      style={{
        backgroundColor: variant === "blue" ? blue : yellow,
        color: variant === "blue" ? yellow : blue,
        border: variant === "yellow" ? `3px solid ${blue}` : "none",
      }}
      className={styles.button}
      onClick={onClick}
    >
      {text} <FaExternalLinkAlt size={18} />
    </button>
  );
}

Button.propTypes = {
  /** The text that will be displayed in the button. */
  text: PropTypes.string.isRequired,
  /** Function that will execute upon clicking the button. */
  onClick: PropTypes.func.isRequired,
  /**
   * Changes the background color and overall theme of the button.
   * Must be either `"blue"` or `"yellow"`.
   */
  variant: PropTypes.oneOf(["blue", "yellow"]),
};
