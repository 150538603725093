import Header from "../components/Header";
import Footer from "../components/Footer";
import "./Apply.css";
// Images
import background from "../assets/student-pictures/D72339_063.jpg";
import unlvLogo from "../assets/logos/UNLV.png";
import csnLogo from "../assets/logos/CSN.png";
import cccLogo from "../assets/logos/CCCprimary-horizontal-white.png";
import nauLogo from "../assets/logos/NAU.png";
// Hooks
import useIsMobile from "../hooks/useIsMobile";

/**
 * A page that provides the user with links to the SNNA institutions' application pages in the form of buttons
 * with the institutions' logos on them.
 */
export default function Apply() {
  const isMobile = useIsMobile(); // Determines whether the user's device can be classified as a mobile device.

  /**
   * Allows the logo images to act as hyperlinks themselves rather than having to
   * wrap them around <a> tags, which would result in bad CSS formatting.
   * @param {string} url The link to the site that will be opened in a new tab.
   */
  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  return (
    <main className='container'>
      <Header
        title='Apply'
        backgroundImage={background}
        headerStyle={{
          backgroundPosition: "center 24%",
          height: isMobile ? "300px" : "400px", // Do this so the header takes up more space on desktop rather than mobile.
        }}
        subheader={
          <p style={{ margin: 0 }}>
            Interested in participating in the Alliance’s Transfer Transition Success 
            Program (TTSP), Postbaccalaureate Transition Success Program (PBTSP), or 
            Undergraduate Research (UGR)?{" "}
            <br />
            <br />
            Click on your corresponding institution below; then complete 
            and submit the application form to your institution's SNNA-LSAMP office.
          </p>
        }
      />
      <div id='main-content' className='boxes-container'>
        {/*-------------------------- UNLV --------------------------*/}
        <div
          id='unlvBlock'
          className='image-container'
          tabIndex={0} // Allows this container to be focused with the tab button.
        >
          <img
            className='images'
            id='unlvImage'
            src={unlvLogo}
            alt='Logo for the University of Nevada, Las Vegas'
          />
          <p id='unlvText'>
            For Application forms please email{" "}
            <a
              className='unlvEmail'
              href='mailto: lsamp@unlv.edu'
              tabIndex={-1}
            >
              lsamp@unlv.edu
            </a>
          </p>
        </div>
        {/*-------------------------- CSN (different from the rest because it has email links) --------------------------*/}
        <div
          id='csnBlock'
          className='image-container'
          tabIndex={0} // Allows this container to be focused with the tab button.
        >
          <img
            className='images'
            id='csnImage'
            src={csnLogo}
            alt='Logo for the College of Southern Nevada'
          />
          <p id='csnText'>
            For a TTSP or UGR application form, please send a request by email to{" "}
            <a
              className='csnEmail'
              href='mailto: lsamp@csn.edu'
              tabIndex={-1}
            >
              lsamp@csn.edu
            </a>
          </p>
        </div>
        {/*-------------------------- CCC --------------------------*/}     
        <div
          id='cccBlock'
          className='image-container'
          tabIndex={0} // Allows this container to be focused with the tab button.
        >
          <img
            className='images'
            id='cccImage'
            src={cccLogo}
            alt='Logo for Coconino Community College'
          />
          <p id='cccText'>
              For an TTSP application form, please click{" "}
              <a href="https://www.coconino.edu/lsamp-contact" class="websiteLink">here</a>{" "}
              and request a form.
          </p>
        </div>
        {/*-------------------------- NAU --------------------------*/}
        <div
          id='nauBlock'
          className='image-container'
          tabIndex={0} // Allows this container to be focused with the tab button.
        >
          <img
            className='images'
            id='nauImage'
            src={nauLogo}
            alt='Logo for Northern Arizona University'
          />
          <p id='nauText'>
            For a PBTSP or UGR application form, please send a request by email to{" "}
            <a
              className='nauEmail'
              href='mailto: lsamp@nau.edu'
              tabIndex={-1}
            >
              lsamp@nau.edu 
            </a>
          </p>
        </div>
      </div>
      <Footer />
    </main>
  );
}
