// Modules
import React, { useRef, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Slider from "react-slick";
import { Link } from "react-router-dom";
// CSS
import "./ProgramBenefits.css";
// Images
import background from "../assets/student-pictures/LSAMPActivity1.png";
import csnLogo from "../assets/logos/CSN.png";
import cccLogo from "../assets/logos/CCCprimary-horizontal-white.png";
import nauLogo from "../assets/logos/NAU.png";
import unlvLogo from "../assets/logos/UNLV.png";
// Icons
import { ImCheckmark } from "react-icons/im";
import { BsChevronRight } from "react-icons/bs";
import { BsChevronLeft } from "react-icons/bs";
import { BsDot } from "react-icons/bs";
// Hooks
import useIsMobile from "../hooks/useIsMobile";

/**
 * This page displays some of the services provided by SNNA-LSAMP in the form of a graph. This graph lists various services along
 * with checkmarks under the schools that actually provide them. Additionally, it contains a carousel with many testimonials
 * from current and previous LSAMP members.
 */
export default function ProgramBenefits() {
  const isMobile = useIsMobile(); // Determine's whether the user is using a mobile device.
  const checkmarkColor = "#10b510"; // The color for every checkmark.
  const checkmarkSize = isMobile ? 20 : 28; // The size of every checkmark.

  /**
   * An array containing every student testimonial as an object. These will become viewable in the carousel at the bottom of the page.
   * For every element in this array, follow this format:
   *                    {
   *                      quote: <string> The student's quote.
   *                      name: <string> The student's name.
   *                      school: <string> The student's school abbreviation (e.g. UNLV, CSN, etc.).
   *                    }
   */
  const testimonials = [
    {
      quote:
        "I feel as though LSAMP has improved my time at NAU so much, opened my eyes to how many opportunities are here, and connected me to some great people on campus.",
      name: "Pedro Camancho",
      school: "NAU",
    },
    {
      quote:
        "Joining and participating in SNNA-LSAMP has been enlightening. At first, I was unsure of how beneficial participation would be, but it has borne fruit in ways I could not foresee. I am grateful to be a part of it.",
      name: "Conzello Barnhill",
      school: "CSN",
    },
    {
      quote:
        "Being an LSAMP participant comes with a lot of perks and one of the best ones, in my opinion, is that you’re getting the opportunity to be paired up with a peer mentor... They’ve inspired me to push myself to do things that I didn’t think I could do before.",
      name: "Yoali Terrones",
      school: "UNLV",
    },
    {
      quote:
        "LSAMP has continued to support me by providing me guidance and meeting with me about my educational goals and plans after college.",
      name: "Mia Cordero",
      school: "CCC",
    },
  ];

  return (
    <main className='container'>
      <Header
        title='Activities and Services'
        backgroundImage={background}
        headerStyle={{ backgroundPosition: "center 35%" }}
        subheader='SNNA-LSAMP offers a variety of activities and services to help students advance through college and toward STEM careers!'
      />
      <h1 style={{ margin: 0 }} id='main-content'>What FREE Activities and Services are Offered?</h1>
      <div className='content'>
        <p style={{ margin: 30 }}>
          The Alliance provides a collection of activities and services offered across 
          one or more SNNA-LSAMP partner campuses.  Please note some SNNA-LSAMP activities 
          such as the Transfer Transition Success Program, the Postbaccalaureate Transition 
          Success Program, and Undergraduate Research Program require interested students to 
          submit an application. Information about each activity and service is shown below.  
          <br />
        </p>
        </div>
        <h3 className='left-aligned' id='content2'>STEM Transfer Transition Success Program</h3>
        <p style={{ margin: 30 }}>
          The STEM Transfer Transition Success Program (TTSP) occurs at 
          College of Southern Nevada (CSN) and Coconino Community College 
          (CCC) with the goal of increasing the number of underrepresented 
          minority (URM) STEM students who transfer to the University of 
          Nevada Las Vegas (UNLV) or Northern Arizona University (NAU). 
          The two-year TTSP provides students with a highly structured set 
          of interventions that promote transfer readiness. Performance-based 
          stipends are available to students who complete the program, which 
          is available to underrepresented minority (URM)* freshmen at CSN and 
          CCC and requires interested students to submit an application to the 
          SNNA-LSAMP office on their campus. (Note: TTSP participants must be U.S. 
          citizens, U.S. nationals, or permanent residents of the United States.) 
          Information about how to submit an application can be found by visiting{" "}
          <Link tabIndex={-1} to='/apply'>
            Apply
          </Link>.
          and then selecting the button corresponding to the Alliance institution where 
          the applicant is enrolled.
        </p>
        <h3 className='left-aligned' id='content2'>Near-Peer Mentoring</h3>
        <p style={{ margin: 30 }}>
          Near-peer mentoring is provided to students enrolled in the TTSP. Community 
          college students at CSN and CCC are mentored by UNLV and NAU upper-level high-achieving 
          STEM students.          
        </p>
        <h3 className='left-aligned' id='content2'>STEM Postbaccalaureate Transition Success Program</h3>
        <p style={{ margin: 30 }}>
          The STEM Post-baccalaureate Transition Success Program (PBTSP) program consists of a 
          series of graduate school preparation events, assisting students with completing and 
          submitting at least two graduate-school applications and submitting at least one 
          application for graduate school funding. Performance-based stipends are available to 
          students who complete the program. Available to URM* STEM majors who are seniors at UNLV 
          and NAU and have a cumulative GPA of 3.0 or higher, this one-year program requires 
          interested students to submit an application to the SNNA-LSAMP office on their campus.  
          (Note: PBTSP participants must be U.S. citizens, U.S. nationals, or permanent residents of 
          the United States.) Information about how to submit an application can be found by visiting{" "}
          <Link tabIndex={-1} to='/apply'>
            Apply
          </Link>{" "}
          and then selecting the button corresponding to the Alliance 
          institution where the applicant is enrolled.
        </p>
        <h3 className='left-aligned' id='content2'>STEM Undergraduate Research</h3>
        <p style={{ margin: 30 }}>
          SNNA-LSAMP-sponsored STEM undergraduate research (UGR) focuses on STEM topics and is 
          supervised by STEM faculty members. UGR occurs at NAU, UNLV, and CSN.  All UGR participants
          produce a technical poster and present at an SNNA-LSAMP Annual Conference.  Performance-
          based stipends are available to students who complete the program.  Available to URM* STEM
          majors who are sophomores or juniors at UNLV, NAU, and CSN and have a cumulative GPA of 3.0 
          or higher, this one-semester program requires interested students to submit an application to 
          the SNNA-LSAMP office on their campus. (Note: UGR participants must be U.S. citizens, U.S. 
          nationals, or permanent residents of the United States.) Information about how to submit an 
          application can be found by visiting{" "}
          <Link tabIndex={-1} to='/apply'>
            Apply
          </Link>{" "}
          and then selecting the button corresponding to the Alliance institution where the applicant is 
          enrolled.
        </p>
        <h3 className='left-aligned' id='content2'>Discord-based Tutoring STEM Gateway Courses</h3>
        <p style={{ margin: 30 }}>
          Discord-based tutoring is offered at NAU, CCC, UNLV, and CSN for students enrolled in targeted 
          STEM gateway courses with historically high rates of students earning grades of D, F, I, or W at 
          multiple Alliance institutions.  The tutoring will be delivered virtually via the Discord Platform. 
          All students enrolled in the targeted courses are eligible to utilize this service. Information 
          about which courses are being targeted in a given semester at an Alliance institution can be 
          obtained by contacting that institution’s SNNA-LSAMP office:{" "}
          <Link tabIndex={-1} to='/connect'>
            Connect
          </Link>.
        </p>
        <h3 className='left-aligned' id='content2'>Outreach to Draw Underrepresented Minority Students to STEM Disciplines</h3>
        <p style={{ margin: 30 }}>
          The Alliance conducts frequent outreach to undeclared and non-STEM freshmen and sophomores at UNLV 
          and NAU to share information about STEM disciplines and career fields. This outreach includes 
          classroom presentations, electronic or hard copy communication, and field trips to STEM 
          laboratories and worksites. UNLV and NAU underclassmen who would like more information about STEM,
           as well as others who are interested in having the Alliance reach out to specific groups of URM* 
          underclassmen at UNLV or NAU, should contact the SNNA-LSAMP office on their campus:{" "}
          <Link tabIndex={-1} to='/connect'>
            Connect
          </Link>.
        </p>
        <h3 className='left-aligned' id='content2'>Annual SNNA-LSAMP Conference</h3>
          <p style={{ margin: 30 }}>
           The annual SNNA-LSAMP Conference will alternate between  Las Vegas, Nevada, and Flagstaff, Arizona, 
           starting in Las Vegas in Spring 2024. The 1.5 day conference will include professional development
            sessions, networking opportunities, and undergraduate research presentations.  First preference 
            for attendance will be given to students enrolled in the TTSP, PBTSP, and UGR. More information 
            about the conference can be obtained from the SNNA-LSAMP office at UNLV:{" "}
          <Link tabIndex={-1} to='/connect'>
            Connect
          </Link>.
          *Note: For LSAMP program purposes, underrepresented minority (URM) students are individuals who are 
          members of groups historically underrepresented in STEM at the undergraduate and graduate-education 
          levels. In accordance with the the solicitation (NSF 20-590) under which the SNNA-LSAMP grant (EES 
          2307200) is funded, students historically underrepresented in STEM include members of one or more of 
          the following groups: African Americans, Hispanic Americans, American Indians, Alaska Natives, Native 
          Hawaiians and Native Pacific Islanders. The purpose of LSAMP nationally and SNNA-LSAMP regionally is to 
          broaden the participation of these students in STEM disciplines and careers.
        </p>  
      <table>
        <thead>
          <tr>
            <th>
              <h1 className='table-cell-header'>SNNA-LSAMP Service/Activity</h1>
            </th>
            <th>
              {/* CSN Header Logo */}
              <img
                className='img'
                src={csnLogo}
                alt='Logo for the College of Southern Nevada.'
              />
            </th>
            <th>
              {/* CCC Header Logo */}
              <img
                className='img'
                src={cccLogo}
                alt='Logo for Coconino Community College.'
              />
            </th>
            <th>
              {/* NAU Header Logo */}
              <img
                id='nauImg'
                className='img'
                src={nauLogo}
                alt='Logo for Northern Arizona University.'
              />
            </th>
            <th>
              {/* UNLV Header Logo */}
              <img
                id='unlvImg'
                className='img'
                src={unlvLogo}
                alt='Logo for the University of Nevada, Las Vegas.'
              />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>STEM Transfer Transition Success Program (TTSP)</td>
            <td>
              <ImCheckmark size={checkmarkSize} color={checkmarkColor} />
            </td>
            <td>
              <ImCheckmark size={checkmarkSize} color={checkmarkColor} />
            </td>
            <td>
            </td>
            <td>
            </td>
          </tr>
          <tr>
            <td>University-to-Community-College Near-Peer Mentoring</td>
            <td>
              <ImCheckmark size={checkmarkSize} color={checkmarkColor} />
            </td>
            <td>
              <ImCheckmark size={checkmarkSize} color={checkmarkColor} />
            </td>
            <td>
            </td>
            <td>
            </td>
          </tr>
          <tr>
            <td>STEM Post-Baccalaureate Transition Success Program (PBTSP)</td>
            <td>
            </td>
            <td>
            </td>
            <td>
              <ImCheckmark size={checkmarkSize} color={checkmarkColor} />
            </td>
            <td>
              <ImCheckmark size={checkmarkSize} color={checkmarkColor} />
            </td>
          </tr>
          <tr>
            <td>STEM Undergraduate Research</td>
            <td>
              <ImCheckmark size={checkmarkSize} color={checkmarkColor} />
            </td>
            <td>
            </td>
            <td>
              <ImCheckmark size={checkmarkSize} color={checkmarkColor} />
            </td>
            <td>
              <ImCheckmark size={checkmarkSize} color={checkmarkColor} />
            </td>
          </tr>
          <tr>
            <td>Cross-Institutional, Discord-Based Tutoring for Select STEM Gateway Courses</td>
            <td>
              <ImCheckmark size={checkmarkSize} color={checkmarkColor} />
            </td>
            <td>
              <ImCheckmark size={checkmarkSize} color={checkmarkColor} />
            </td>
            <td>
              <ImCheckmark size={checkmarkSize} color={checkmarkColor} />
            </td>
            <td>
              <ImCheckmark size={checkmarkSize} color={checkmarkColor} />
            </td>
          </tr>
          <tr>
            <td>Outreach to Draw Underrepresented Minority University Undergraduates into STEM Disciplines</td>
            <td>
            </td>
            <td>
            </td>
            <td>
              <ImCheckmark size={checkmarkSize} color={checkmarkColor} />
            </td>
            <td>
              <ImCheckmark size={checkmarkSize} color={checkmarkColor} />
            </td>
          </tr>
          <tr>
            <td>Annual Conference</td>
            <td>
              <ImCheckmark size={checkmarkSize} color={checkmarkColor} />
            </td>
            <td>
              <ImCheckmark size={checkmarkSize} color={checkmarkColor} /> 
            </td>
            <td>
              <ImCheckmark size={checkmarkSize} color={checkmarkColor} />
            </td>
            <td>
              <ImCheckmark size={checkmarkSize} color={checkmarkColor} />
            </td>
          </tr>
        </tbody>
      </table>

      <h1>Hear From Past and Current SNNA-LSAMP Participants!</h1>
      <Carousel data={testimonials} />

      <Footer />
    </main>
  );
}

/**
 * Component that creates a revolving slideshow (carousel) out of quotes from LSAMP members.
 * @param {array} data An array of objects that are formatted as such:
 *                    {
 *                      quote: <string> The student's quote.
 *                      name: <string> The student's name.
 *                      school: <string> The student's school abbreviation (e.g. UNLV, CSN, etc.).
 *                    }
 */
function Carousel({ data }) {
  const carousel = useRef(); // Used to target the Slider component's methods.
  const isMobile = useIsMobile();
  const [index, setIndex] = useState(0); // Keeps track of the current slide.
  const [dotsDisabled, setDotsDisabled] = useState(false); // Dictates whether the dots should be unclickable; prevents the index from changing before an animation has completed.
  const primaryColor = "#255ba8"; // Color used for the arrows and bottom dots.
  const arrowSize = 90; // Size of the arrows (in pixels).

  return (
    <div className='carousel-container'>
      {/*------------------- Previous Arrow -------------------*/}
      <BsChevronLeft
        style={{ display: isMobile ? "none" : "block" }} // Hides the arrow on mobile devices.
        className='carousel-arrow'
        color={primaryColor}
        size={arrowSize}
        onClick={() => carousel.current.slickPrev()} // Slides to the previous quote when clicked.
      />
      {/*------------------- Slider (Carousel) -------------------*/}
      <div className='carousel'>
        <Slider
          className='carousel-inner'
          ref={carousel}
          /* swipeToSlide */
          dots
          appendDots={(dots) => {
            return (
              /**
               * Create a dot for every quote provided.
               * These dots indicate to the user which slide they are currently viewing.
               * @param i The slide index associated with each individual dot.
               */
              <div>
                {dots.map((item, i) => (
                  <BsDot
                    key={i} // Needed for dynamically generated components.
                    className={
                      // When the current slide matches the individual dot's associated index, add the "activeDot" class to it.
                      `dots ${i === index ? "activeDot" : ""}`
                    }
                    size={35}
                    color={primaryColor}
                    // When a dot is clicked, update the index and go to that dot's associated slide (so long as they're not disabled).
                    onClick={() => {
                      if (!dotsDisabled) {
                        setIndex(i);
                        carousel.current.slickGoTo(i);
                      }
                    }}
                  />
                ))}
              </div>
            );
          }}
          beforeChange={(oldIndex, newIndex) => {
            // Every time an arrow is clicked, change the index state to the newIndex.
            setIndex(newIndex);
            setDotsDisabled(true);
          }}
          afterChange={() => setDotsDisabled(false)}
          arrows={false} // Don't use the component's pre-supplied arrows.
          autoplay // Automatically scroll through all the slides.
          autoplaySpeed={6000} // Time (in milliseconds) before the slider automatically slides to the next quote.
          speed={isMobile ? 300 : 800} // How slowly the slider moves to the next quote (slower on mobile).
          /* cssEase={"ease-out"} */
        >
          {data.map((d) => {
            return (
              <div className='quote-container' key={d.name}>
                <div>
                  <h2 className='quote'>
                    "<strong>{d.quote}</strong>"
                  </h2>
                  <p className='identity'>
                    {d.name}
                    <br />
                    SNNA-LSAMP participant at {d.school}
                  </p>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
      {/*------------------- Next Arrow -------------------*/}
      <BsChevronRight
        style={{ display: isMobile ? "none" : "block" }} // Hides the arrow on mobile devices.
        className='carousel-arrow'
        color={primaryColor}
        size={arrowSize}
        onClick={() => carousel.current.slickNext()} // Slides to the next quote when clicked.
      />
    </div>
  );
}
